<template>
  <b-row>
    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Información</h3>
        </b-col>
        <b-col cols="12" class="hp-profile-content-list mt-8 pb-8" v-if="place">
          <ul>
            <li>
              <span class="hp-p1-body">Nombre</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ place.name }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Código</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ place.code }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Proyecto</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ place.project.name }}
              </span>
            </li>
            <li class="mt-18" v-if="place.commune">
              <span class="hp-p1-body">Comuna</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ place.commune.name }}
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12" md="8" v-if="place">
      <h3>Dirección</h3>
      <p class="hp-p1-body mb-0" v-html="place.address"></p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col
      cols="12"
      md="8"
      v-if="
        place &&
        currentUser &&
        checkIfHaveRole(currentUser.rol, [ROLES.superadmin])
      "
    >
      <h3>Gestión</h3>
      <div class="d-flex align-items-center">
        <b-button
          variant="warning"
          class="btn-ghost"
          @click="$refs.editPlace.show(place)"
          >Editar Punto</b-button
        >
        <b-button variant="danger" @click="deletePlace" class="mx-2 btn-ghost"
          >Eliminar Punto</b-button
        >
      </div>
    </b-col>

    <update-or-create-place
      @success="getPlace($route.params.id)"
      ref="editPlace"
    ></update-or-create-place>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UpdateOrCreatePlace from "../updateOrCreatePlace.vue";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [verifyRole],
  computed: {
    ...mapGetters({
      place: "place/place",
      currentUser: "auth/currentUser",
    }),
  },
  components: {
    UpdateOrCreatePlace,
  },
  methods: {
    ...mapActions({
      getPlace: "place/getPlace",
      sendDeletePlace: "place/deletePlace",
    }),
    deletePlace() {
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: "¿Estas seguro de eliminar este punto de instalación?.",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",

          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.sendDeletePlace(this.place.id);
            if (resp.status == 204) {
              this.$bvToast.toast(
                `Punto de instalación eliminado correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
              setTimeout(() => {
                this.$router.push({
                  name: "show-project-information",
                  params: {
                    id: this.place.project.id,
                  },
                });
              }, 1500);
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>
