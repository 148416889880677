<template>
  <b-modal
    id="modal-upload-or-generate-file"
    hide-footer
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">Subir PDF a Plataforma de Proyectos</h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>
    <section class="mb-8">
      <b-row align-content="center" align-h="center">
        <b-col cols="12" md="6" sm="12" align-self="center">
          <div class="d-flex justify-content-center flex-column">
            <div class="mx-auto">
							<b-button type="submit"  variant="outline-warning" @click="openInputPDF">
                <b-spinner
									v-if="loading"
									small
									type="grow"
									></b-spinner>
									<i v-if="!loading" class="iconly-Curved-Upload"></i>
							</b-button>
            </div>
            <span class="text-center mt-2">
							Subir PDF
            </span>
            <input
							id="file-pdf"
							style="display: none"
							@change="setFile"
							type="file"
							placeholder="Seleccionar Archivo"
							accept=".pdf"
						/>
          </div>
        </b-col>
        <b-col cols="12" md="6" sm="12" align-self="center">
          <div class="d-flex justify-content-center flex-column">
            <div class="mx-auto">
							<b-button
               @click="generateAndSendPdfPlaceChecklist"
                type="submit"  variant="outline-warning">
                <b-spinner
									v-if="loadingGeneratePdf"
									small
									type="grow"
									></b-spinner>
									<i v-if="!loadingGeneratePdf" class="iconly-Curved-Document"></i>
							</b-button>
            </div>
            <span class="text-center mt-2">
							Generar y subir PDF
            </span>
          </div>
        </b-col>
      </b-row>
      </div>
    </section>
    <section class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
    </section>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      loadingGeneratePdf: false,
      currentPlaceChecklistId: null,
      form: {
        file: null,
      },
    };
  },
  methods: {
    ...mapActions({
        uploadPdfFromPlaceChecklist: 'checklist/uploadPdfFromPlaceChecklist',
        getDocxFromPlaceChecklist: "checklist/getDocxFromPlaceChecklist",
    }),
    openInputPDF() {
      document.getElementById("file-pdf").click();
    },
		isValidFilePDF(file){
			return file.type == 'application/pdf'
		},
    setFile(e) {
      if (e.target.files.length) {
				if(this.isValidFilePDF(e.target.files[0])){
					this.form.file = e.target.files[0];
					this.uploadFile();
				}else {
					return this.$bvToast.toast(`El archivo debe ser formato PDF`, {
					title: `Error`,
					variant: "danger",
					solid: false,
					});
				}
      }
    },
    async uploadFile() {
      this.loading = true;
      let self = this;
      let response = await self.uploadPdfFromPlaceChecklist({
         id: self.currentPlaceChecklistId,
         file: self.form.file,
      });
			if(response.status == 200){
				self.$bvToast.toast(
					`Archivo cargado correctamente a plataforma de proyectos`,
					{
					title: `Excelente`,
					variant: "primary",
					solid: false,
					}
				);
				self.loading = false;
				self.closeModal();
				self.$emit("pdfUploaded");
			}
			if (response.status == 400 || response.status == 500) {
				self.loading = false;
				return self.$bvToast.toast(`Ha ocurrido un error al cargar el PDF, inténtalo nuevamente`, {
				title: `Error`,
				variant: "danger",
				solid: false,
				});
			}
    },
     async generateAndSendPdfPlaceChecklist() {
      this.$bvToast.toast(`Se esta generando y subiendo el documento, espere un poco`, {
        title: `Atención`,
        variant: "info",
        solid: false,
      });
      this.loadingGeneratePdf = true;
      const response = await this.getDocxFromPlaceChecklist({
        place_checklist_id: this.currentPlaceChecklistId,
        autoupload: true,
      });
      if(response.status == 200){
        this.$bvToast.toast(
          `Archivo generado y subido correctamente a plataforma de proyectos`,
          {
          title: `Excelente`,
          variant: "primary",
          solid: false,
          }
        );
        this.loadingGeneratePdf = false;
        this.closeModal();
        this.$emit("pdfUploaded");
      }
			if (response.status == 400 || response.status == 500) {
				this.loadingGeneratePdf = false;
				return this.$bvToast.toast(`Ha ocurrido un error al generar el PDF, inténtalo nuevamente`, {
				title: `Error`,
				variant: "danger",
				solid: false,
				});
			}
    },
    goToQuestionsChecklist(item) {
      this.$router.push({
        name: "place-checklist",
        params: {
          id: this.$route.params.id,
          checklistId: item.checklist.id,
        },
      });
    },
    openModal(id) {
      this.currentPlaceChecklistId = id
      this.$bvModal.show("modal-upload-or-generate-file");
    },
    closeModal() {
      this.currentPlaceChecklistId = null
      this.$bvModal.hide("modal-upload-or-generate-file");
    },
  },
  computed: {},
};
</script>

<style></style>
