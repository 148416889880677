<template>
  <b-col class="hp-profile-menu py-24 px-0" style="flex: 0 0 220px">
    <div class="w-100" v-if="place">
      <div class="hp-profile-menu-header mt-16 mt-lg-0 text-center">
        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <img
              width="160px"
              style="border-radius: 0.6rem"
              height="155px"
              :src="
                place.image
                  ? place.image
                  : require('@/assets/img/app/places/place-icon.png')
              "
            />
          </div>
        </div>

        <h5 class="mt-24 mb-4">{{ place.name }}</h5>
        <a class="hp-p1-body">
          {{ place.project.name }}
        </a>
      </div>
    </div>

    <div
      class="hp-profile-menu-body w-100 text-left mt-48 mt-lg-0"
      v-if="place"
    >
      <ul>
        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'show-place-information',
              params: {
                id: place.id,
              },
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == `/app/places/${place.id}/information` ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Document mr-8"></i>
            <span>Información</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'show-place-users',
              params: {
                id: place.id,
              },
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == `/app/places/${place.id}/users` ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-User mr-8"></i>
            <span>Miembros</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'show-place-checklists',
              params: {
                id: place.id,
              },
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == `/app/places/${place.id}/checklists` ? ' active' : ''
            }`"
          >
            <i class="iconly-Curved-Folder mr-8"></i>
            <span>Plantillas</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'show-project-information',
              params: {
                id: place.project.id,
              },
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == `/app/projects/${place.project.id}/information`
                ? ' active'
                : ''
            }`"
          >
            <i class="iconly-Curved-ArrowLeft mr-8"></i>
            <span>Volver</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
      </ul>
    </div>
    <div class="hp-profile-menu-footer">Puntos de instalación</div>
  </b-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      path: "",
    };
  },
  watch: {
    $route() {
      this.getRoute();
    },
  },
  methods: {
    getRoute() {
      this.path = this.$route.path;
    },
  },
  created() {
    this.getRoute();
  },
  computed: {
    ...mapGetters({
      place: "place/place",
    }),
  },
};
</script>
