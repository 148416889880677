<template>
  <b-modal id="modal-place-user" hide-footer header-class="align-items-center">
    <template #modal-header="{ close }">
      <h5 class="mb-0">
        {{ form.id ? "Actualizar miembro" : "Agregar miembro" }}
      </h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>

    <b-form class="mb-8">
      <b-form-group label="Usuario :" label-for="checklistUser" class="mb-16">
        <div v-if="form.id">
          <span v-if="findedUser">
            {{ findedUser.first_name }} {{ findedUser.last_name }} ({{ findedUser.email }})
          </span>
          <span v-else> Super Administrador </span>
        </div>

        <v-select
          v-if="!form.id"
          id="checklistUser"
          label="first_name"
          :reduce="(user) => user.id"
          v-model="form.customuser"
          :class="{ 'is-invalid': $v.form.customuser.$error }"
          :options="users"
          placeholder="Selecciona un usuario"
        >
          <template #selected-option="{ first_name, last_name, email }">
            <div>
              <span v-if="first_name">
                {{ first_name }} {{ last_name }} ({{ email }})
              </span>
              <span v-else> Super Administrador </span>
            </div>
          </template>
          <template #option="{ first_name, last_name, email }">
            <div>
              <span v-if="first_name">
                {{ first_name }} {{ last_name }} ({{ email }})
              </span>
              <span v-else> Super Administrador </span>
            </div>
          </template>
          <template #no-options="{ search, searching, loading }">
            Sin resultados.
          </template>
        </v-select>
        <div v-if="$v.form.customuser.$error" class="invalid-feedback">
          <span v-if="!$v.form.customuser.required">Usuario es requerido.</span>
        </div>
      </b-form-group>
      <b-form-group
        label="Rol del miembro :"
        label-for="rolPlaceUser"
        class="mb-16"
      >
        <b-form-select
          v-model="form.rol"
          :options="wrappedRoles"
          id="rolPlaceUser"
          class="mb-1"
          :class="{ 'is-invalid': $v.form.rol.$error }"
          value-field="id"
          text-field="name"
        ></b-form-select>
        <div v-if="$v.form.rol.$error" class="invalid-feedback">
          <span v-if="!$v.form.rol.required">Rol es requerido.</span>
        </div>
      </b-form-group>
      <b-form-group
        label="Sección del miembro :"
        label-for="sectionPlaceUser"
        class="mb-16"
      >
        <v-select
          id="sectionPlaceUser"
          v-model="form.section"
          label="name"
          multiple
          :reduce="(e) => e.id"
          :options="sections"
          placeholder="Selecciona una Sección"
        >
          <template #no-options> Sin Resultados. </template>
        </v-select>
        <div
          v-if="$v.form.section.$error"
          class="invalid-feedback"
          :style="`display: ${$v.form.section.$error ? 'block' : 'none'}`"
        >
          <span v-if="!$v.form.section.required">Sección es requerido.</span>
        </div>
      </b-form-group>
    </b-form>
    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
      <b-button
        type="submit"
        class="ml-16"
        variant="outline-primary"
        @click="handleSubmit"
      >
        <b-spinner v-if="loading" small type="grow" class="mr-8"></b-spinner>
        {{ form.id ? "Actualizar miembro" : "Agregar miembro" }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import { verifyRole } from "@/mixins/role";
import { ROLES } from "@/constants/roles";

export default {
  mixins: [validationMixin, verifyRole],
  mounted() {
    this.getRoles();
    this.getUsers();
  },
  data() {
    return {
      loading: false,
      findedUser: null,
      form: {
        id: null,
        customuser: null,
        rol: null,
        section: [],
      },
    };
  },
  validations: {
    form: {
      customuser: {
        required,
      },
      rol: {
        required,
      },
      section: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      updateOrCreatePlaceUser: "place/updateOrCreatePlaceUser",
      getRoles: "user/getRoles",
      getUsers: "user/getUsers",
    }),
    show(placeUser) {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      if (placeUser) {
        this.findedUser = this.users.find((e) => e.id == placeUser.customuser.id);
        this.form = {
          id: placeUser.id,
          customuser: placeUser.customuser.id,
          rol: placeUser.rol.id,
          section: placeUser.section
            .split(",")
            .map((e) => Number.parseInt(e, 10)),
        };
      } else {
        this.findedUser = null;
      }
      this.$bvModal.show("modal-place-user");
    },
    async handleSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const { id, ...rest } = this.form;
        let response;
        if (id) {
          response = await this.updateOrCreatePlaceUser({
            ...rest,
            id,
            place: this.$route.params.id,
            section: rest.section.join(","),
          });
        } else {
          response = await this.updateOrCreatePlaceUser({
            ...rest,
            place: this.$route.params.id,
            section: rest.section.join(","),
            // rol: this.ROLES.installer,
          });
        }
        this.loading = false;
        if (response.status == 201 || response.status == 200) {
          this.$bvToast.toast(
            `Miembro ${id ? "actualizado" : "agregado"} correctamente`,
            {
              title: `Excelente`,
              variant: "primary",
              solid: false,
            }
          );
          this.closeModal();
          this.$emit("success");
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-place-user");
      this.form = {
        id: null,
        customuser: null,
        rol: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      roles: "user/roles",
      users: "user/users",
      sections: "project/sections",
    }),
    wrappedRoles() {
      return [{ id: null, name: "Selecciona un Rol" }, ...this.roles].filter(
        (item) => {
          return item.id !== ROLES.superadmin;
        }
      );
    },
  },
};
</script>

<style></style>
