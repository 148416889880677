var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"hp-profile-menu py-24 px-0",staticStyle:{"flex":"0 0 220px"}},[(_vm.place)?_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"hp-profile-menu-header mt-16 mt-lg-0 text-center"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-inline-block position-relative"},[_c('img',{staticStyle:{"border-radius":"0.6rem"},attrs:{"width":"160px","height":"155px","src":_vm.place.image
                ? _vm.place.image
                : require('@/assets/img/app/places/place-icon.png')}})])]),_c('h5',{staticClass:"mt-24 mb-4"},[_vm._v(_vm._s(_vm.place.name))]),_c('a',{staticClass:"hp-p1-body"},[_vm._v(" "+_vm._s(_vm.place.project.name)+" ")])])]):_vm._e(),(_vm.place)?_c('div',{staticClass:"hp-profile-menu-body w-100 text-left mt-48 mt-lg-0"},[_c('ul',[_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == `/app/places/${_vm.place.id}/information` ? ' active' : ''
          }`,attrs:{"to":{
            name: 'show-place-information',
            params: {
              id: _vm.place.id,
            },
          }}},[_c('i',{staticClass:"iconly-Curved-Document mr-8"}),_c('span',[_vm._v("Información")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == `/app/places/${_vm.place.id}/users` ? ' active' : ''
          }`,attrs:{"to":{
            name: 'show-place-users',
            params: {
              id: _vm.place.id,
            },
          }}},[_c('i',{staticClass:"iconly-Curved-User mr-8"}),_c('span',[_vm._v("Miembros")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == `/app/places/${_vm.place.id}/checklists` ? ' active' : ''
          }`,attrs:{"to":{
            name: 'show-place-checklists',
            params: {
              id: _vm.place.id,
            },
          }}},[_c('i',{staticClass:"iconly-Curved-Folder mr-8"}),_c('span',[_vm._v("Plantillas")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == `/app/projects/${_vm.place.project.id}/information`
              ? ' active'
              : ''
          }`,attrs:{"to":{
            name: 'show-project-information',
            params: {
              id: _vm.place.project.id,
            },
          }}},[_c('i',{staticClass:"iconly-Curved-ArrowLeft mr-8"}),_c('span',[_vm._v("Volver")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1)])]):_vm._e(),_c('div',{staticClass:"hp-profile-menu-footer"},[_vm._v("Puntos de instalación")])])
}
var staticRenderFns = []

export { render, staticRenderFns }